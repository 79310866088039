<template>
	<div>
		<div id="scrollbar">
            <div id="scrollbar-bg"></div>
        </div>
		<header class="header_white">
			<Navbar logoColor="true"></Navbar>
		</header>
		<section id="about" class="bg_lighgrey_pattern">
			<div id="post" class="container">
				<div class="row">
					<div class="col-12 col-lg-10 offset-lg-1 aos-init" data-aos="fade-up" data-aos-duration="1000">
            			<img class="img-fluid img--animated-soft" :src="require(`@/assets/blog/eeml-2025.png`)" alt="EEML 2025" height="" width="">
						<h3 class="title_xl title_lightblue mt-4 mb-3">
							EEML 2025: Pridružite se vrhunskim AI stručnjacima!
						</h3>

            <div class="text_bold text_m mt-4 mb-4">
              <div class="row px-3">
                <div class="d-inline-block mr-3">
                  <img class="rounded-circle" :src="require(`@/assets/blog/milica.jpeg`)" width="40" height="40">
                </div>
                <div class="d-inline-block">
                  Milica Brković<br>
                  Mar 13, 2025
                </div>
              </div>
            </div>
						<div class="reading-time badge badge-danger d-inline-block px-2">
							<details>
							  <summary>1 minut</summary><span></span>
							</details>
						</div>
            <hr/>
						<p class="text_xl  my-3">
							Veštačka inteligencija se ubrzano razvija i oblikuje savremenu nauku i industriju, a kvalitetna edukacija i umrežavanje imaju presudnu ulogu u izgradnji snažne AI zajednice.
							<br><br>
							<strong>Istočnoevropska letnja škola mašinskog učenja</strong> (<a href="https://www.eeml.eu/" target="_blank">Eastern European Machine Learning Summer School – EEML</a>) predstavlja jednu od najznačajnijih inicijativa za jačanje istraživačke zajednice u našem regionu.
							<br><br>
							Tim naučnika iz kompanije „Google DeepMind“ u saradnji sa <a href="https://annt.ba/" target="_blank">Asocijacijom za napredak nauke i tehnologije</a> iz Bosne i Hercegovine realizuje ovogodišnji EEML, koji će se održati u <strong>Sarajevu od 21. do 26. jula 2025</strong>. godine. <a href="https://www.eeml.eu/application" target="_blank">Prijave su otvorene</a> <strong>do 31. marta 2025. godine</strong>.
							<br><br>
							Nakon prošlogodišnjeg uspešnog izdanja u Novom Sadu, EEML 2025 prvi put dolazi u Bosnu i Hercegovinu, uz podršku vodećih istraživačkih institucija i kompanija iz oblasti veštačke inteligencije.
							<br><br>
							Kako EEML doprinosi mladim istraživačima i stručnjacima iz Srbije i regiona, zašto je važno učestvovati na ovakvim događajima i koje prilike donosi učesnicima – teme su o kojima smo razgovarali sa članovima organizacionog odbora EEML 2025.
							<br><br>
							Naučnici iz kompanije „Google DeepMind“ <strong>dr Petar Veličković</strong> i <strong>Hamza Merzić</strong> za našu platformu govore o značaju edukativnih inicijativa, povezivanju istraživača i perspektivama razvoja AI zajednice.
						</p>
						<h3 class="title_m title_lightblue my-4 my-lg-5">
							Dr Petar Veličković, Google DeepMind
						</h3>
						<h4 class="title_xs title_lightblue">
							Kakva je uloga događaja poput EEML za istraživače i mlade stručnjake iz Srbije i regiona, posebno kada je reč o razvoju karijere u oblasti mašinskog učenja?
						</h4>
						<p class="text_xl  my-3">
							Glavni uspeh inicijativa poput EEML jeste da, pre svega, one dovode elemente najvećih svetskih konferencija veštačke inteligencije (prestižne govornike, uticajne svetske firme, istraživače i naučnike iz celog sveta) na naše govorno područje, kao i bez potrebe za plaćanjem velikih putnih troškova, i samim tim su daleko pristupačnije za mlade stručnjake iz regiona.
						</p>
						<h4 class="title_xs title_lightblue">
							Zašto je učešće mladih naučnika na EEML važno i kakve im prilike ovakve edukativne inicijative mogu doneti?
						</h4>
						<p class="text_xl  my-3">
							Pre svega, puno jako korisnih kontakata i prijateljstava za budućnost, jer ovakve letnje škole privlače stotine mladih talenata iz čitavog sveta, koji će vrlo verovatno biti buduće kolege našim naučnicima.
							<br><br>
							A i zatim, EEML nudi vrlo direktan pristup i razgovor sa naučnicima sa kojima naši stručnjaci retko imaju priliku da interaguju uživo. Ovo je takođe često jako dragoceno iskustvo.
						</p>

						<h3 class="title_m title_lightblue my-4 my-lg-5">
							Hamza Merzić, Google DeepMind
						</h3>
						<h4 class="title_xs title_lightblue">
							Koliko je važno imati događaje poput EEML u regionu i kako oni utiču na razvoj lokalne istraživačke zajednice?
						</h4>
						<p class="text_xl  my-3">
							Iako EEML u Sarajevu još nije ni započeo, meni je zaista nevjerovatno biti svjedok mobilizacije istraživača u regionu i uvezivanja različitih istraživačkih grupa koje su se okupile kako bi se ovaj događaj ostvario.
							<br>
							Događaji poput EEML često za cilj upravo imaju pružanje što veće koristi regionalnoj istraživačkoj zajednici.
							<br>
							Ali na kraju dana je ipak do samih učesnika da prepoznaju ono što je njima relevantno; da li je to nešto što im je neposredno korisno, poput saradnji na konkretnim projektima ili mentorstvo ili indirektno korisno kroz dugoročne i teže mjerljive stvari poput konekcija ili čak prijateljstava.
							<br>
							Ovakvi događaji su, nažalost, još uvijek rijetkost u našem regionu, zbog čega bih posebno ohrabrio sve zainteresovane da se prijave i iskoriste prednost domaćeg terena.
						</p>
						<h4 class="title_xs title_lightblue">
							Na koji način EEML doprinosi umrežavanju mladih istraživača iz regiona i njihovom povezivanju sa međunarodnim stručnjacima?
						</h4>
						<p class="text_xl  my-3">
							Mislim da je našim ljudima ponekad teško prepoznati značaj indirektnih prilika, poput umrežavanja, koje ovakvi događaji donose. S druge strane, interesantno je spomenuti da neke firme koje danas sponzorišu EEML vode upravo ljudi koje smo upoznali na sličnim događajima još kao studenti.
							<br><br>
							EEML okuplja izvrsne istraživače u ranim fazama njihovih karijera, tako da konekcije ostvarene ovdje imaju ogroman potencijal i mnogi učesnici kasnije postanu izvrsni istraživači i uspješni poduzetnici.
							<br><br>
							Uz to, ne smijemo zaboraviti ni sponzore koji će biti dio šestodnevnog programa i dodatno ga obogatiti uvidima i kontaktima iz realnog sektora. I naravno, tu su i investitori koji mogu biti značajan kontakt u budućnosti za istraživače koji odluče komercijalizirati svoje ideje. Prilike za region su zaista velike i bila bi šteta ne iskoristiti ih.

						</p>
						<h4 class="title_xs title_lightblue">
							Kakve novosti možemo očekivati na EEML 2025?
						</h4>
						<p class="text_xl  my-3">
							Trudimo se da kroz program pratimo najvažnija dešavanja u svijetu vještačke inteligencije. Ove godine ćemo imati priliku ugostiti člana tima nagrađenog prošlogodišnjom Nobelovom nagradom iz hemije za rješavanje problema protein-foldinga – jednog od najvećih izazova u historiji biologije.
							<br><br>
							Pored toga, planiramo omogućiti manjim firmama da održe kraća predavanja o primjenama vještačke inteligencije u industriji, kako bismo zaklopili cjelinu i studentima bolje predstavili mogućnosti tehnologija o kojima uče na EEML.

						</p>
						
					</div>
				</div>
			</div>
		</section>
		<Footer bg="footer_bg-red"></Footer>
	</div>
</template>

<script>
import Navbar from "@/components/layout/Navbar"
import Footer from "@/components/layout/Footer"

export default{
	name: "EEML2025",

	components: {
		Navbar,
		Footer
	},
	methods: {
		getWordCount(){
			const post = document.getElementById("post");
			return post.innerText.match(/\w+/g).length;
		},

		setReadingTime(){
			const avgWordsPerMin = 250;
			const readingTimeSummary = document.querySelector(".reading-time");

			let count = this.getWordCount();
			let time = Math.ceil(count / avgWordsPerMin);

			readingTimeSummary.innerText = time + " min čitanja";
		}
	},

  	mounted() {
		this.setReadingTime();

      	var bar_bg = document.getElementById("scrollbar-bg"),
			body = document.body,
			html = document.documentElement;

		bar_bg.style.minWidth = document.width + "px";

		document.getElementsByTagName("body")[0].onresize = function() {
			// Update the gradient width
			bar_bg.style.minWidth = document.width + "px";
		}

		window.onscroll = function() {
			// Change the width of the progress bar
			var bar = document.getElementById("scrollbar"),
				dw  = document.documentElement.clientWidth,
				dh  = Math.max( body.scrollHeight, body.offsetHeight,
							html.clientHeight, html.scrollHeight, html.offsetHeight ),
				wh  = window.innerHeight,
				pos = pageYOffset || (document.documentElement.clientHeight ?
								document.documentElement.scrollTop : document.body.scrollTop),
				bw  = ((pos / (dh - wh)) * 100);

			bar.style.width = bw + "%";
		}
  	}
}

</script>
